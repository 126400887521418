<template>
  <div class="min-h-screen bg-gray-100 flex flex-col">
    <header class="bg-indigo-700 text-white py-6 shadow-lg">
      <div class="container mx-auto px-4">
        <h1 class="text-4xl font-bold">Fake Screenshot Generator</h1>
        <p class="text-xl mt-2 italic">"If there's a screenshot, it must be true!"</p>
      </div>
    </header>
    <main class="flex-grow">
      <div class="container mx-auto px-4 py-8">
        <div class="max-w-6xl mx-auto">
          <ScreenshotGenerator />
        </div>
      </div>
    </main>
    <footer class="bg-gray-200 py-4 mt-8">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-between items-center">
          <p class="text-gray-600 text-sm">&copy; {{ new Date().getFullYear() }} FakeScreenshots.com. All rights reserved.</p>
          <a href="https://www.buymeacoffee.com/johnnygu" target="_blank" rel="noopener noreferrer" class="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition duration-300">Buy Me A Coffee</a>
          <details class="text-left">
            <summary class="cursor-pointer text-indigo-600 hover:text-indigo-800 font-semibold">Terms of Use</summary>
            <div class="mt-2 bg-white p-4 rounded-lg shadow-md">
              <h4 class="font-bold mb-2">Disclaimer and Terms of Use</h4>
              <ol class="list-decimal list-inside space-y-2">
                <li>This website is for entertainment and creative purposes only.</li>
                <li>Users are solely responsible for the content they generate and share.</li>
                <li>Do not use copyrighted images without proper permission.</li>
                <li>FakeScreenshots.com does not claim ownership of any generated content.</li>
                <li>We are not responsible for how the generated content is used or shared.</li>
                <li>By using this service, you agree not to create content that violates any laws or regulations.</li>
                <li>FakeScreenshots.com reserves the right to modify or terminate the service at any time.</li>
              </ol>
            </div>
          </details>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ScreenshotGenerator from './components/ScreenshotGenerator.vue'

export default {
  name: 'App',
  components: {
    ScreenshotGenerator
  }
}
</script>