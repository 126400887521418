<template>
  <div class="bg-white shadow-lg rounded-lg overflow-hidden">
    <div class="md:flex">
      <LeftPanel
        v-model:celebrity="celebrity"
        v-model:captions="captions"
        v-model:fontSize="fontSize"
        v-model:captionHeight="captionHeight"
        v-model:removeWatermark="removeWatermark"
        :builtInImages="builtInImages"
        :captionPlaceholder="captionPlaceholder"
        @save-image="saveImage"
        @share-on-twitter="shareOnTwitter"
        @share-on-facebook="shareOnFacebook"
        @upload-image="uploadImage"
        @toggle-watermark="toggleWatermark"
        class="md:w-1/2 p-6"
      />
      <RightPanel
        :celebrity="celebrity"
        :captions="captions || captionPlaceholder"
        :fontSize="fontSize"
        :captionHeight="captionHeight"
        :removeWatermark="removeWatermark"
        :uploadedImage="uploadedImage"
        class="md:w-1/2 p-6 bg-gray-100"
      />
    </div>
    <div v-if="showPopup" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white p-6 rounded-lg shadow-xl">
        <h2 class="text-xl font-bold mb-4">Support Us</h2>
        <p class="mb-4">If you enjoy using this tool, consider supporting to cover the server expense:</p>
        <a href="https://www.buymeacoffee.com/johnnygu" target="_blank" rel="noopener noreferrer" class="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition duration-300">Buy Me A Coffee</a>
        <button @click="closePopup" class="ml-4 text-gray-600 hover:text-gray-800">No, thanks</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import LeftPanel from './LeftPanel.vue'
import RightPanel from './RightPanel.vue'
import config from '@/config.json'

export default {
  name: 'ScreenshotGenerator',
  components: {
    LeftPanel,
    RightPanel
  },
  setup() {
    const celebrity = ref(config.defaultCelebrity)
    const captions = ref('')
    const fontSize = ref(config.defaultFontSize)
    const captionHeight = ref(config.defaultCaptionHeight)
    const removeWatermark = ref(false)
    const uploadedImage = ref(null)
    const showPopup = ref(false)
    const hasSeenPopup = ref(false)
    const builtInImages = ref([
      { value: 'Donald Trump.jpg', label: 'Donald Trump' },
      { value: 'Dwayne Johnson.jpg', label: 'Dwayne Johnson' },
      { value: 'Elon Musk.jpg', label: 'Elon Musk' },
      { value: 'Kevin Hart.jpg', label: 'Kevin Hart' },
      { value: 'Robert Downey Jr.jpg', label: 'Robert Downey Jr.' },
    ])
    const captionPlaceholder = ref(getRandomCaption())

    function getRandomCaption() {
      const index = Math.floor(Math.random() * config.captionOptions.length)
      return config.captionOptions[index]
    }

    const saveImage = async () => {
      const canvas = document.querySelector('canvas')
      if (!canvas) return
      const link = document.createElement('a')
      link.href = canvas.toDataURL()
      link.download = 'fake-screenshot.png'
      link.click()
    }

    const shareOnTwitter = () => {
      const text = encodeURIComponent("Check out this hilarious fake screenshot I made!")
      const url = encodeURIComponent("https://fakescreenshots.com")
      window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}`, "_blank")
    }

    const shareOnFacebook = () => {
      const url = encodeURIComponent("https://fakescreenshots.com")
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, "_blank")
    }

    const uploadImage = (event) => {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          uploadedImage.value = e.target.result
          celebrity.value = '' // Clear selected celebrity when uploading custom image
        }
        reader.readAsDataURL(file)
      }
    }

    const toggleWatermark = () => {
      if (!removeWatermark.value && !hasSeenPopup.value) {
        showPopup.value = true
        hasSeenPopup.value = true
      } else {
        removeWatermark.value = !removeWatermark.value
      }
    }

    const closePopup = () => {
      showPopup.value = false
      removeWatermark.value = true
    }

    watch(celebrity, () => {
      if (celebrity.value) {
        uploadedImage.value = null // Reset uploaded image when a celebrity is selected
      }
    })

    return {
      celebrity,
      captions,
      fontSize,
      captionHeight,
      removeWatermark,
      uploadedImage,
      builtInImages,
      captionPlaceholder,
      showPopup,
      saveImage,
      shareOnTwitter,
      shareOnFacebook,
      uploadImage,
      toggleWatermark,
      closePopup
    }
  }
}
</script>