<template>
  <div class="space-y-6">
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2" for="celebrity">
        Choose your celebrity
      </label>
      <select
        id="celebrity"
        v-model="celebrityModel"
        class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
      >
        <option v-for="image in builtInImages" :key="image.value" :value="image.value">
          {{ image.label }}
        </option>
      </select>
    </div>
    <div>
      <label class="cursor-pointer bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded-lg inline-block transition duration-300 w-full text-center">
        Upload Your Celebrity
        <input
          type="file"
          @change="$emit('upload-image', $event)"
          class="hidden"
          accept="image/*"
        >
      </label>
    </div>
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2" for="captions">
        Captions
      </label>
      <textarea
        id="captions"
        v-model="captionsModel"
        rows="4"
        :placeholder="captionPlaceholder"
        class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
      ></textarea>
    </div>
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2" for="font-size">
        Font Size
      </label>
      <input
        type="range"
        id="font-size"
        v-model="fontSizeModel"
        min="12"
        max="36"
        class="w-full"
      >
      <div class="text-sm text-gray-600 mt-1">{{ fontSizeModel }}</div>
    </div>
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2" for="caption-height">
        Caption Height
      </label>
      <input
        type="range"
        id="caption-height"
        v-model="captionHeightModel"
        min="30"
        max="100"
        class="w-full"
      >
      <div class="text-sm text-gray-600 mt-1">{{ captionHeightModel }}</div>
    </div>
    <div>
      <label class="flex items-center">
        <input
          type="checkbox"
          :checked="removeWatermark"
          @change="$emit('toggle-watermark')"
          class="mr-2 leading-tight"
        >
        <span class="text-sm font-bold text-gray-700">Remove watermark</span>
      </label>
    </div>
    <div>
      <button
        @click="$emit('save-image')"
        class="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 mb-2"
      >
        Save Image
      </button>
      <div class="flex justify-center space-x-4">
        <button @click="$emit('share-on-twitter')" class="text-blue-400 hover:text-blue-600">
          <i class="fab fa-twitter fa-2x"></i>
        </button>
        <button @click="$emit('share-on-facebook')" class="text-blue-600 hover:text-blue-800">
          <i class="fab fa-facebook fa-2x"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'LeftPanel',
  props: {
    celebrity: String,
    captions: String,
    fontSize: Number,
    captionHeight: Number,
    removeWatermark: Boolean,
    builtInImages: Array,
    captionPlaceholder: String
  },
  emits: [
    'update:celebrity', 
    'update:captions', 
    'update:fontSize', 
    'update:captionHeight', 
    'update:removeWatermark', 
    'save-image', 
    'share-on-twitter', 
    'share-on-facebook', 
    'upload-image',
    'toggle-watermark'
  ],
  setup(props, { emit }) {
    const celebrityModel = computed({
      get: () => props.celebrity,
      set: (value) => emit('update:celebrity', value)
    })

    const captionsModel = computed({
      get: () => props.captions,
      set: (value) => emit('update:captions', value)
    })

    const fontSizeModel = computed({
      get: () => props.fontSize,
      set: (value) => emit('update:fontSize', Number(value))
    })

    const captionHeightModel = computed({
      get: () => props.captionHeight,
      set: (value) => emit('update:captionHeight', Number(value))
    })

    const removeWatermarkModel = computed({
      get: () => props.removeWatermark,
      set: (value) => emit('update:removeWatermark', value)
    })

    return {
      celebrityModel,
      captionsModel,
      fontSizeModel,
      captionHeightModel,
      removeWatermarkModel
    }
  }
}
</script>