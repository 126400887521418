<template>
  <div class="w-full md:w-1/2">
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <canvas ref="canvas" class="w-full"></canvas>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'

export default {
  name: 'RightPanel',
  props: {
    celebrity: String,
    captions: String,
    fontSize: Number,
    captionHeight: Number,
    removeWatermark: Boolean,
    uploadedImage: String
  },
  setup(props) {
    const canvas = ref(null)
    const ctx = ref(null)
    const image = ref(new Image())
    const font = ref(null)

    onMounted(() => {
      ctx.value = canvas.value.getContext('2d')
      image.value.onload = updateCanvas
      updateImageSource()
      loadFont()
    })

    watch([() => props.celebrity, () => props.uploadedImage], updateImageSource)
    watch([() => props.captions, () => props.fontSize, () => props.captionHeight, () => props.removeWatermark], updateCanvas)

    function updateImageSource() {
      image.value.src = props.uploadedImage || (props.celebrity ? `/images/${encodeURIComponent(props.celebrity)}` : '/images/default.jpg')
    }

    function loadFont() {
      const link = document.createElement('link')
      link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'
      link.rel = 'stylesheet'
      document.head.appendChild(link)

      font.value = new FontFace('Roboto', 'url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2)')
      font.value.load().then(() => {
        document.fonts.add(font.value)
        updateCanvas()
      })
    }

    function updateCanvas() {
      if (!ctx.value || !image.value.complete || !font.value) return

      const lines = props.captions.split('\n')
      const totalHeight = image.value.height + (lines.length > 1 ? (lines.length - 1) * props.captionHeight : 0)

      canvas.value.width = image.value.width
      canvas.value.height = totalHeight

      ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height)
      ctx.value.drawImage(image.value, 0, 0)

      ctx.value.font = `${props.fontSize}px Roboto, sans-serif`
      ctx.value.fillStyle = 'white'
      ctx.value.textAlign = 'center'
      ctx.value.textBaseline = 'middle'
      ctx.value.strokeStyle = 'black'
      ctx.value.lineWidth = 3

      // Draw first line on the main image
      if (lines.length > 0) {
        const y = image.value.height - props.captionHeight / 2
        ctx.value.strokeText(lines[0], canvas.value.width / 2, y)
        ctx.value.fillText(lines[0], canvas.value.width / 2, y)
      }

      // Draw remaining lines on additional images
      lines.slice(1).forEach((line, index) => {
        const y = image.value.height + (index + 0.5) * props.captionHeight
        
        // Draw partial image
        ctx.value.drawImage(
          image.value,
          0, image.value.height - props.captionHeight, image.value.width, props.captionHeight,
          0, y - props.captionHeight / 2, image.value.width, props.captionHeight
        )
        
        // Draw text
        ctx.value.strokeText(line, canvas.value.width / 2, y)
        ctx.value.fillText(line, canvas.value.width / 2, y)
      })

      if (!props.removeWatermark) {
        ctx.value.font = '14px Roboto, sans-serif'
        ctx.value.fillStyle = 'rgba(255, 255, 255, 0.5)'
        ctx.value.textAlign = 'right'
        ctx.value.textBaseline = 'bottom'
        ctx.value.fillText('www.fakescreenshots.com', canvas.value.width - 10, totalHeight - 10)
      }
    }

    return {
      canvas
    }
  }
}
</script>